import * as React from "react";
import Plx from "react-plx";
import CollabBios from "./collab-bios.js";

const Collab = (props) => {
  let aData = [
    {
      start: props.start + props.height / 4,
      end: props.start + props.height * 1.5,
      properties: [
        {
          startValue: 70,
          endValue: 50,
          property: "top",
          unit: "vh",
        },
      ],
    },
    {
      start: props.start + props.height * 1.5,
      end: props.start + props.height * 2.5 + props.height / 4,
      properties: [
        {
          startValue: 50,
          endValue: 225,
          property: "top",
          unit: "vh",
        },
      ],
    },
  ];
  let bData = [
    {
      start: props.start + props.height / 4,
      end: props.start + props.height * 1.5,
      properties: [
        {
          startValue: 70,
          endValue: 50,
          property: "top",
          unit: "vh",
        },
      ],
    },
    {
      start: props.start + props.height * 1.5,
      end: props.start + props.height * 2,
      properties: [
        {
          startValue: 50,
          endValue: 90,
          property: "top",
          unit: "vh",
        },
      ],
    },
    {
      start: props.start + props.height * 2,
      end: props.start + props.height * 2.5 + props.height / 4,
      properties: [
        {
          startValue: 90,
          endValue: 195,
          property: "top",
          unit: "vh",
        },
      ],
    },
  ];
  let cData = [
    {
      start: props.start + props.height / 4,
      end: props.start + props.height * 1.5,
      properties: [
        {
          startValue: 70,
          endValue: 50,
          property: "top",
          unit: "vh",
        },
      ],
    },
    {
      start: props.start + props.height * 1.5,
      end: props.start + props.height * 2,
      properties: [
        {
          startValue: 50,
          endValue: 90,
          property: "top",
          unit: "vh",
        },
      ],
    },
    {
      start: props.start + props.height * 2,
      end: props.start + props.height * 2.5,
      properties: [
        {
          startValue: 90,
          endValue: 150,
          property: "top",
          unit: "vh",
        },
      ],
    },
    {
      start: props.start + props.height * 2.5,
      end: props.start + props.height * 2.5 + props.height / 4,
      properties: [
        {
          startValue: 150,
          endValue: 177,
          property: "top",
          unit: "vh",
        },
      ],
    },
  ];
  let copyData = [
    {
      start: props.start + props.height * 1.25,
      end: props.start + props.height * 2.25,
      easing: [0.42, 0.0, 1.0, 1.0],
      properties: [
        {
          startValue: 250,
          endValue: 165,
          property: "top",
          unit: "vh",
        },
      ],
    },
  ];

  const collabRef = React.useRef(null);

  React.useEffect(() => {
    props.total(collabRef.current ? collabRef.current.clientHeight : 0);
    return () => {};
  });

  return (
    <div ref={collabRef} className="collab">
      <div className="collab-cs">
        <Plx
          className="c"
          animateWhenNotInViewport={true}
          parallaxData={cData}
        />
        <Plx
          className="b"
          animateWhenNotInViewport={true}
          parallaxData={bData}
        />
        <Plx
          className="a"
          animateWhenNotInViewport={true}
          parallaxData={aData}
        />
        <Plx
          className="copy"
          animateWhenNotInViewport={true}
          parallaxData={copyData}
        >
          <h2>X</h2>
          <h3>COLLABS</h3>
        </Plx>
      </div>
      <CollabBios />
    </div>
  );
};

export default Collab;
