import * as React from "react";
import Smilesss from "../../images/2.0/smilesss.png";
import LogoSmilesss from "../../images/2.0/logo-smilesss.png";
import TheHeartProject from "../../images/2.0/the-heart-project.png";
import Javier from "../../images/2.0/javier-arres.jpg";
import Sophie from "../../images/2.0/sophie-sturdevant.jpg";
import Sean from "../../images/2.0/sean-williams.jpg";

const CollabBios = (props) => {
  return (
    <div className="collab-white">
      <div className="bios">
        <div className="bio">
          <div
            className="image"
            style={{ backgroundImage: `url(${Smilesss})` }}
          ></div>
          <h2>
            SMILESSSVRS <img src={LogoSmilesss} height="45" align="top" />
            <br />
            &nbsp;
          </h2>
          <p>
            SMILESSS was born out of the convergence of streetwear, technology,
            hip-hop, and fashion. Co-Founder Giovanni Gussen says: “We aim to
            redefine luxury in a way that is not derivative of the traditional
            definition, but instead, creates a new one both physically &
            digitally and without exclusivity. Our mission is to establish a
            forward-thinking brand that appeals to an eclectic audience — from
            the high-end hipster who appreciates innovative design, to the
            streetwear enthusiast who craves new aesthetics and urban culture,
            but more than this, we look to take things one step further and
            create an experience around the Smilesss brand like no other.”
          </p>
          <p>
            SMILESSS is more than just a brand, they’re a cultural movement
            about seeing and approaching the world differently. SMILESSS Founder
            Waheed Zai, the talented artist and creator behind of all
            Smilesssvrs aims to capture the brand in a way that can be
            understood and embodied across all mediums while conveying the
            emotional messages of the community, and their collective energy.
          </p>
          <p style={{ textAlign: "right" }}>
            <a target="_blank" href="https://www.smilesss.com/">
              Learn More
            </a>
          </p>
        </div>
        <div className="bio">
          <div
            className="image"
            style={{ backgroundImage: `url(${TheHeartProject})` }}
          ></div>
          <h2>
            THE HEART
            <br />
            PROJECT
          </h2>
          <p>
            THE HEART PROJECT is a wildly popular community-run Web3 project
            founded by Aidan Cullen and Stefan Meier, which enables passionate
            lovers of creativity to shape the art we interact with. The Heart
            Project Creative Studio is designed to produce media with thousands
            of contributors on every project and grants ownership of their
            shared creations.
          </p>
          <p>
            As a special collaboration, THE HEART PROJECT is inviting their
            community to design a one-off sneaker skin for the CULT&RAIN Genesis
            drop.
          </p>
          <p>
            Aidan Cullen is a renowned photographer and filmmaker based in Los
            Angeles & NYC. His multi-disciplinary art navigates between music,
            fashion, documentary and narrative worlds.
          </p>
          <p>
            Stefan Meier is a highly respected artist based in Los Angeles. He
            works in painting, drawing, collage, and textile design. His
            artistic expression often delves into humankind’s relationship with
            nature.
          </p>
          <p style={{ textAlign: "right" }}>
            <a target="_blank" href="https://www.heartnfts.io/">
              Learn More
            </a>
          </p>
        </div>
        <div className="bio">
          <div
            className="image"
            style={{ backgroundImage: `url(${Sean})` }}
          ></div>
          <h2>
            SEAN
            <br />
            WILLIAMS
          </h2>
          <p>
            Sean is a celebrated digital artist, animator and owner of
            WEIRDWILDWORLD, a one-man-run streetwear/art brand. Hailing from
            Eanston, Illinois, SEAN WILLIAMS has made a clear impact on the art
            scene with his distinct signature style he calls GLÖSS. “I try my
            best to think and create from the same spaces I did when I was a
            kid: pure exploration, free from judgment”. This unique artistic
            partnership is a manifestation of the nascent Web3 fashion movement,
            fittingly in the form of NFT sneaker art. From skin to sole, one
            exclusive model of the CULT & RAIN Genesis Drop will have Sean’s
            unique artistic vision defined.
          </p>
          <p style={{ textAlign: "right" }}>
            <a href="https://twitter.com/iArtSometimes" target="_blank">
              Learn More
            </a>
          </p>
        </div>
        <div className="bio">
          <div
            className="image"
            style={{ backgroundImage: `url(${Sophie})` }}
          ></div>
          <h2>
            SOPHIE
            <br />
            STURDEVANT
          </h2>
          <p>
            Chicago-based artist SOPHIE STURDEVANT tells visual stories by
            playing with lines and layers in a style she calls SuperStacked.
            Sophie works in both physical and digital spaces. Regardless of
            media, her work and process plays on a "museum and mayhem”
            juxtaposition, leveraging line work and stippling to tell visual
            stories of womanhood — which is, like her work, complicated,
            abstract, and emotionally overwhelming. Sophie’s art pieces are
            stunning displays of active physical creation on a 3D canvas;
            meeting CULT & RAIN in its core ambition to celebrate and promote
            groundbreaking art and culture.
          </p>
          <p style={{ textAlign: "right" }}>
            <a href="https://sophiesturdevant.com" target="_blank">
              Learn More
            </a>
          </p>
        </div>
        <div className="bio">
          <div
            className="image"
            style={{ backgroundImage: `url(${Javier})` }}
          ></div>
          <h2>
            JAVIER
            <br />
            ARRES
          </h2>
          <p>
            CULT & RAIN has teamed up with JAVIER ARRES, one of the world’s
            leading motion / GIF artists and a 2019 London Art Biennale winner.
            Inspired by the sweeping views from his childhood home overlooking
            his hometown of Motril, Spain, Arrés is known for his manically
            detailed illustrations of fantastical scenery from cities,
            architecture and machines. He further brings these worlds to life
            through what he calls “Visual Toys”. Through these Visual Toys,
            Arrés invites the viewer into a fantastical world, inspiring the
            viewer to simply play with their imaginations.
          </p>
          <p style={{ textAlign: "right" }}>
            <a href="https://makersplace.com/javierarres/" target="_blank">
              Learn More
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CollabBios;
