import * as React from "react"

const isBrowser = typeof window !== "undefined";
// markup
const Presale = (props) => {

  const presaleRef = React.useRef(null);

  React.useEffect(() => {
    props.total((presaleRef.current?presaleRef.current.clientHeight:0));
    return () => {}
  });

  return (

    <div ref={presaleRef} className="presale">
      <div className="presale_copy">
        <h2>UTILITIES</h2>
        <h5>WE VALUE COMMUNITY FIRST</h5>
        <p style={{marginTop:'0px'}}>Every NFT minted includes unique rarity attributes, select governance<br />rights, and one of a kind redeemables with VIP access.</p>
        <h3>INTRODUCING THE ULTIMATE<br /><strong>RARITY SCALE</strong></h3>
        <p style={{marginTop:'0px'}}>To learn more about our GENESIS DROP UTILITIES please click below.</p>
        <p><a href="/redeemables">LEARN MORE</a></p>
      </div>
      <video autoPlay loop muted>
        <source src="https://cdn.cultandrain.com/DiamondLights.mp4" type="video/mp4" />
      </video>

    </div>
  )
}

export default Presale;
