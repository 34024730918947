import * as React from "react";
import Img from "../../images/2.0/collab-static.png";
import CollabBios from "./collab-bios.js";

const CollabStatic = (props) => {
  const collabRef = React.useRef(null);

  React.useEffect(() => {
    props.total(collabRef.current ? collabRef.current.clientHeight : 0);
    return () => {};
  });

  return (
    <div ref={collabRef} className="collab">
      <div className="collab-static">
        <img src={Img} alt="Collabs to be announced" />
      </div>
      <CollabBios />
    </div>
  );
};

export default CollabStatic;
