import * as React from "react";
import Plx from "react-plx";
import Shoe1 from "../../images/2.0/shoe_white.png";

const isBrowser = typeof window !== "undefined";

// markup
const Shoes = (props) => {
  const shoesRef = React.useRef(null);
  const whiteRef = React.useRef(null);
  const copyRef = React.useRef(null);
  const fireRowRef = React.useRef(null);

  const [hasStarted, setHasStarted] = React.useState(false);

  let start = props.start;

  let stages = [
    props.height * 0.5,
    props.height * 0.7,
    copyRef.current ? copyRef.current.clientHeight : 0,
    fireRowRef.current ? fireRowRef.current.clientHeight : 0,
    fireRowRef.current ? fireRowRef.current.clientHeight : 0,
    fireRowRef.current ? fireRowRef.current.clientHeight : 0,
  ];

  let totalHeight = stages.reduce((a, b) => a + b);

  let shoe1Data = [
    {
      start: props.start + props.height,
      end: props.start + stages[0] + stages[1] + stages[1] / 2,
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start: props.start + props.height,
      end: props.start + stages[0] + stages[1] + stages[1] / 2,
      easing: [0.42, 0.0, 1.0, 1.0],
      properties: [
        {
          startValue: 1,
          endValue: 3,
          property: "scale",
        },
      ],
    },
    {
      start: props.start + props.height,
      end: props.start + stages[0] + stages[1] + stages[1] / 2,
      easing: [0.42, 0.0, 1.0, 1.0],
      properties: [
        {
          startValue: 0,
          endValue: props.orientation == "portrait" ? 75 : 55,
          property: "top",
          unit: "vh",
        },
      ],
    },
    {
      start: props.start + props.height,
      end: props.start + stages[0] + stages[1] + stages[1] / 2,
      easing: [0.42, 0.0, 1.0, 1.0],
      properties: [
        {
          startValue: 0,
          endValue: props.orientation == "portrait" ? 25 : 20,
          property: "left",
          unit: "vw",
        },
      ],
    },
  ];

  let shoeNameData = [
    {
      start: props.start + stages[0] + stages[1],
      end: props.start + stages[0] + stages[1] + stages[1] / 2,
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start: props.start + stages[0] + stages[1],
      end: props.start + stages[0] + stages[1] + stages[1] / 2,
      properties: [
        {
          startValue: -15,
          endValue: 30,
          property: "top",
          unit: "vh",
        },
      ],
    },
  ];

  let arLinkData = [
    {
      start: props.start + stages[0] + stages[1],
      end: props.start + stages[0] + stages[1] + stages[1] / 2,
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start: props.start + stages[0] + stages[1],
      end: props.start + stages[0] + stages[1] + stages[1] / 2,
      properties: [
        {
          startValue: 80,
          endValue: 52,
          property: "left",
          unit: "vw",
        },
      ],
    },
  ];

  let copyData = [
    {
      start: props.start + stages[0] + stages[1],
      end: props.start + stages[0] + stages[1] + stages[2],
      properties: [
        {
          startValue: 40,
          endValue: 0,
          property: "translateY",
          unit: "vh",
        },
      ],
    },
  ];

  let fireShoeDataA = [
    {
      start: props.start + stages[0] + stages[1] + stages[2],
      end: props.start + stages[0] + stages[1] + stages[2] + stages[3],
      properties: [
        {
          startValue: 0.6,
          endValue: 1,
          property: "scale",
        },
      ],
    },
  ];

  let shoeNameDataA = [
    {
      start: props.start + stages[0] + stages[1] + stages[2],
      end: props.start + stages[0] + stages[1] + stages[2] + stages[3],
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start: props.start + stages[0] + stages[1] + stages[2],
      end: props.start + stages[0] + stages[1] + stages[2] + stages[3],
      properties: [
        {
          startValue: props.orientation == "portrait" ? 0 : 10,
          endValue: 0,
          property: "right",
          unit: "vw",
        },
      ],
    },
  ];

  let fireShoeDataB = [
    {
      start:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[3] / 2,
      end:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[4] +
        stages[4] / 2,
      properties: [
        {
          startValue: 0.6,
          endValue: 1,
          property: "scale",
        },
      ],
    },
  ];

  let shoeNameDataB = [
    {
      start:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[3] / 2,
      end:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[4] +
        stages[4] / 2,
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[3] / 2,
      end:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[4] +
        stages[4] / 2,
      properties: [
        {
          startValue: props.orientation == "portrait" ? 17 : 10,
          endValue: props.orientation == "portrait" ? 17 : 0,
          property: "left",
          unit: "vw",
        },
      ],
    },
  ];

  let fireShoeDataC = [
    {
      start:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[4] +
        stages[4] / 2,
      end:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[4] +
        stages[5] +
        stages[5] / 2,
      properties: [
        {
          startValue: 0.6,
          endValue: 1,
          property: "scale",
        },
      ],
    },
  ];

  let shoeNameDataC = [
    {
      start:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[4] +
        stages[4] / 2,
      end:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[4] +
        stages[5] +
        stages[5] / 2,
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[4] +
        stages[4] / 2,
      end:
        props.start +
        stages[0] +
        stages[1] +
        stages[2] +
        stages[3] +
        stages[4] +
        stages[5] +
        stages[5] / 2,
      properties: [
        {
          startValue: props.orientation == "portrait" ? 0 : 10,
          endValue: 0,
          property: "right",
          unit: "vw",
        },
      ],
    },
  ];

  const updateScrollPosition = () => {
    if (!hasStarted && window.scrollY >= props.start) {
      setHasStarted(true);
    }
  };

  React.useEffect(() => {
    if (isBrowser) window.addEventListener("scroll", updateScrollPosition);
    props.total(shoesRef.current ? shoesRef.current.clientHeight : 0);
    return () => {
      if (isBrowser) window.removeEventListener("scroll", updateScrollPosition);
    };
  });

  return (
    <div
      ref={shoesRef}
      className="shoes"
      style={{ minHeight: `${totalHeight}px` }}
    >
      <div className="shoes_top_container">
        <div className="shoes_top">
          <div className="shoe_top" style={{ position: "relative" }}>
            <video
              playsInline
              loop
              muted
              autoPlay
              style={{ position: "absolute" }}
            >
              <source
                src="https://cdn.cultandrain.com/shoe_white.mp4"
                type="video/mp4"
              />
            </video>
            <div className="shoe_white">
              <Plx
                tagName="img"
                animateWhenNotInViewport={true}
                parallaxData={shoe1Data}
                src={Shoe1}
              />
            </div>
            <Plx className="ar-link-white" parallaxData={arLinkData}>
              <a href="/GenesisDropAR">
                <svg width="73" height="73" viewBox="0 0 73 73" fill="none">
                  <rect
                    x="0.5"
                    y="0.5"
                    width="72"
                    height="71.8103"
                    rx="19.5"
                    stroke="white"
                  />
                  <path
                    d="M20.4579 19.14H26.2629L40.1229 51H32.2029L29.4579 44.25H17.0829L14.4279 51H6.68791L20.4579 19.14ZM23.1579 28.41L19.2879 38.31H27.0729L23.1579 28.41ZM38.3274 19.14H50.6574C52.2774 19.14 53.8074 19.305 55.2474 19.635C56.7174 19.935 57.9924 20.46 59.0724 21.21C60.1824 21.93 61.0524 22.92 61.6824 24.18C62.3424 25.41 62.6724 26.955 62.6724 28.815C62.6724 31.065 62.0874 32.985 60.9174 34.575C59.7474 36.135 58.0374 37.125 55.7874 37.545L63.8874 51H55.4724L48.8124 38.265H45.3474V51H38.3274V19.14ZM45.3474 32.325H49.4874C50.1174 32.325 50.7774 32.31 51.4674 32.28C52.1874 32.22 52.8324 32.085 53.4024 31.875C53.9724 31.635 54.4374 31.275 54.7974 30.795C55.1874 30.315 55.3824 29.64 55.3824 28.77C55.3824 27.96 55.2174 27.315 54.8874 26.835C54.5574 26.355 54.1374 25.995 53.6274 25.755C53.1174 25.485 52.5324 25.305 51.8724 25.215C51.2124 25.125 50.5674 25.08 49.9374 25.08H45.3474V32.325Z"
                    fill="white"
                  />
                </svg>
                <br />
                Check out our AR experience
              </a>
            </Plx>
          </div>
          <div className="shoe_top">
            <video playsInline loop muted autoPlay>
              <source
                src="https://cdn.cultandrain.com/shoe_camou.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="shoe_top">
            <video playsInline loop muted autoPlay>
              <source
                src="https://cdn.cultandrain.com/titan_xfactor.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="shoe_top">
            <video playsInline loop muted autoPlay>
              <source
                src="https://cdn.cultandrain.com/shoe_pink.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="shoe_top">
            <video playsInline loop muted autoPlay>
              <source
                src="https://cdn.cultandrain.com/shoe_green.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="ar-link">
          <a href="/GenesisDropAR">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none">
              <rect
                x="0.5"
                y="0.5"
                width="72"
                height="71.8103"
                rx="19.5"
                stroke="black"
              />
              <path
                d="M20.4579 19.14H26.2629L40.1229 51H32.2029L29.4579 44.25H17.0829L14.4279 51H6.68791L20.4579 19.14ZM23.1579 28.41L19.2879 38.31H27.0729L23.1579 28.41ZM38.3274 19.14H50.6574C52.2774 19.14 53.8074 19.305 55.2474 19.635C56.7174 19.935 57.9924 20.46 59.0724 21.21C60.1824 21.93 61.0524 22.92 61.6824 24.18C62.3424 25.41 62.6724 26.955 62.6724 28.815C62.6724 31.065 62.0874 32.985 60.9174 34.575C59.7474 36.135 58.0374 37.125 55.7874 37.545L63.8874 51H55.4724L48.8124 38.265H45.3474V51H38.3274V19.14ZM45.3474 32.325H49.4874C50.1174 32.325 50.7774 32.31 51.4674 32.28C52.1874 32.22 52.8324 32.085 53.4024 31.875C53.9724 31.635 54.4374 31.275 54.7974 30.795C55.1874 30.315 55.3824 29.64 55.3824 28.77C55.3824 27.96 55.2174 27.315 54.8874 26.835C54.5574 26.355 54.1374 25.995 53.6274 25.755C53.1174 25.485 52.5324 25.305 51.8724 25.215C51.2124 25.125 50.5674 25.08 49.9374 25.08H45.3474V32.325Z"
                fill="black"
              />
            </svg>
            <br />
            Check out our AR experience
          </a>
        </div>
      </div>
      <div className="shoes_copy">
        <div className="shoe_white_mobile">
          <img src={Shoe1} alt="Titan" />
        </div>
        <Plx className="shoe_name shoe_name_" parallaxData={shoeNameData}>
          <div>
            <h3>TITAN</h3>
            <h4>01</h4>
            <p>Model</p>
          </div>
        </Plx>
        <div className="copy" ref={copyRef}>
          <Plx
            animateWhenNotInViewport={true}
            className="copy_anim"
            parallaxData={copyData}
          >
            <h2>
              GENESIS
              <br />
              DROP
            </h2>
            {/* <h5>WE EXCEED ALL</h5> */}
            {/* <p>
              Our first CULT & RAIN drop is made for crypto enthusiasts who have
              an affinity for stunning{" "}
              <strong>3D NFT artwork and cutting-edge designs;</strong> bridging
              the gap between digital and physical reality.
            </p> */}
            <p>
              <strong>1406 4K animated NFTs</strong> matched with{" "}
              <strong>1406 trend setting physical</strong> sneakers will thrill
              discerning collectors worldwide and make a bold entry into the
              fashion Web3 Universe.
            </p>
            <p>
              <strong>Limited Genesis Edition</strong>
              <br />4 radical sneaker designs
              <br />5 unique colorways each
              <br />Physical sneaker redemption window is now CLOSED
            </p>
            <p>
              <strong>Highest Quality</strong>
              <br />
              “MADE IN ITALY” physical luxury, sourcing the finest materials and
              fabrication through our coveted Italian supply chains.
            </p>
            <h5>WE PLANT THE FLAG</h5>
            <p>
              CULT & RAIN releases the full genesis mint of its NFTs and
              REALWORLD SNEAKERS to the masses.
            </p>
            <p>
              <strong>FASHION</strong>
              <br />
              <strong>WEB3</strong>
              <br />
              <strong>GAME CHANGE</strong>
            </p>
          </Plx>
        </div>
        <div className="fire">
          <div className="shoe_container" ref={fireRowRef}>
            <div className="shoe_box">
              <Plx
                animateWhenNotInViewport={true}
                className="shoe_name shoe_name_a"
                parallaxData={shoeNameDataA}
              >
                <div>
                  <h3>ATLAS</h3>
                  <h4>02</h4>
                  <p>Model</p>
                </div>
              </Plx>
            </div>
            <Plx parallaxData={fireShoeDataA} className="shoea">
              <video playsInline loop muted autoPlay>
                <source
                  src="https://cdn.cultandrain.com/ATLAS_SHUFFLE_THEONE.mp4"
                  type="video/mp4"
                />
              </video>
            </Plx>
          </div>
          <div className="shoe_container">
            <div className="shoe_box hide-desktop">
              <Plx
                animateWhenNotInViewport={true}
                className="shoe_name shoe_name_b"
                parallaxData={shoeNameDataB}
              >
                <div>
                  <h3>VEGA</h3>
                  <h4>03</h4>
                  <p>Model</p>
                </div>
              </Plx>
            </div>

            <Plx className="shoeb" parallaxData={fireShoeDataB}>
              <video playsInline loop muted autoPlay>
                <source
                  src="https://cdn.cultandrain.com/VEGA_SHUFFLE.mp4"
                  type="video/mp4"
                />
              </video>
            </Plx>

            <div className="shoe_box hide-mobile">
              <Plx
                animateWhenNotInViewport={true}
                className="shoe_name shoe_name_b"
                parallaxData={shoeNameDataB}
              >
                <div>
                  <h3>VEGA</h3>
                  <h4>03</h4>
                  <p>Model</p>
                </div>
              </Plx>
            </div>
          </div>
          <div className="shoe_container">
            <div className="shoe_box">
              <Plx
                animateWhenNotInViewport={true}
                className="shoe_name shoe_name_c"
                parallaxData={shoeNameDataC}
              >
                <div>
                  <h3>ORION</h3>
                  <h4>04</h4>
                  <p>Model</p>
                </div>
              </Plx>
            </div>
            <Plx parallaxData={fireShoeDataC} className="shoec">
              <video playsInline loop muted autoPlay>
                <source
                  src="https://cdn.cultandrain.com/ORION_SHUFFLE.mp4"
                  type="video/mp4"
                />
              </video>
            </Plx>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shoes;
