import * as React from "react";
import Plx from "react-plx";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const isBrowser = typeof window !== "undefined";

const Nft = (props) => {
  const nftRef = React.useRef(null);
  const boxRef = React.useRef(null);
  const titanRef = React.useRef(null);
  const atlasRef = React.useRef(null);
  const vegaRef = React.useRef(null);
  const orionRef = React.useRef(null);
  const titan2Ref = React.useRef(null);
  const atlas2Ref = React.useRef(null);
  const vega2Ref = React.useRef(null);
  const orion2Ref = React.useRef(null);
  const theOneRef = React.useRef(null);

  const [playing, setPlaying] = React.useState(false);
  const [muted, setMuted] = React.useState(true);
  const [isVisible, setIsVisible] = React.useState(false);
  const [slideIndex, setSlideIndex] = React.useState(0);

  const toggleMuted = () => {
    setMuted(!muted);
  };

  const observer = new IntersectionObserver(([entry]) =>
    setIsVisible(entry.isIntersecting)
  );

  const updateScrollPosition = () => {
    if (nftRef.current) {
      if (!isVisible) {
        titanRef.current.pause();
        atlasRef.current.pause();
        vegaRef.current.pause();
        orionRef.current.pause();
        titan2Ref.current.pause();
        atlas2Ref.current.pause();
        vega2Ref.current.pause();
        orion2Ref.current.pause();
        theOneRef.current.pause();
        setPlaying(false);
      } else {
        if (!playing) {
          titanRef.current.play();
          atlasRef.current.play();
          vegaRef.current.play();
          orionRef.current.play();
          titan2Ref.current.play();
          atlas2Ref.current.play();
          vega2Ref.current.play();
          orion2Ref.current.play();
          theOneRef.current.play();
        }
        setPlaying(true);
      }
    }
  };

  React.useEffect(() => {
    if (isBrowser) window.addEventListener("scroll", updateScrollPosition);
    if (nftRef.current) observer.observe(nftRef.current);
    props.total(nftRef.current ? nftRef.current.clientHeight : 0);
    return () => {
      if (isBrowser) window.removeEventListener("scroll", updateScrollPosition);
    };
  });

  const properties = {
    autoplay: false,
    indicators: false,
    transitionDuration: 0,
    onChange: (previous, next) => {
      setSlideIndex(next);
    },
    prevArrow: (
      <div className="prev-arrow">
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            d="M34 25L61.5 49.5L38 74"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </div>
    ),
    nextArrow: (
      <div className="next-arrow">
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            d="M38 25L61.5 49.5L38 74"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </div>
    ),
  };

  return (
    <div ref={nftRef} className="nft">
      <div className="copy-left">
        <div className="copy-absolute">
          <h2>
            4K
            <span className="hide-desktop">
              <br />
              FULLY
              <br />
              ANIMATED
            </span>
            <br />
            3D NFTs
          </h2>
          <p style={{ opacity: slideIndex == 0 ? 1 : 0 }}>
            <strong>TITAN</strong>
            <br />
            Emerald Rarity
            <br />
            Limited Edition 005/100
          </p>
          <p style={{ opacity: slideIndex == 1 ? 1 : 0 }}>
            <strong>ATLAS</strong>
            <br />
            Sapphire Rarity
            <br />
            Limited Edition 023/100
          </p>
          <p style={{ opacity: slideIndex == 2 ? 1 : 0 }}>
            <strong>VEGA</strong>
            <br />
            Topaz Rarity
            <br />
            Limited Edition 048/100
          </p>
          <p style={{ opacity: slideIndex == 3 ? 1 : 0 }}>
            <strong>ORION</strong>
            <br />
            Ruby Rarity
            <br />
            Limited Edition 002/100
          </p>
          <p style={{ opacity: slideIndex == 4 ? 1 : 0 }}>
            <strong>TITAN</strong>
            <br />
            Opal Rarity
            <br />
            Limited Edition 001/100
            <br />
            <br />
            by
            <br />
            Sophie Sturdevant
          </p>
          <p style={{ opacity: slideIndex == 5 ? 1 : 0 }}>
            <strong>ATLAS</strong>
            <br />
            Opal Rarity
            <br />
            Limited Edition 001/100
            <br />
            <br />
            by
            <br />
            Sean Williams
          </p>
          <p style={{ opacity: slideIndex == 6 ? 1 : 0 }}>
            <strong>VEGA</strong>
            <br />
            Opal Rarity
            <br />
            Limited Edition 001/100
            <br />
            <br />
            by
            <br />
            The Heart Project Community
          </p>
          <p style={{ opacity: slideIndex == 7 ? 1 : 0 }}>
            <strong>ORION</strong>
            <br />
            Opal Rarity
            <br />
            Limited Edition 001/100
            <br />
            <br />
            by
            <br />
            Javier Arrés
          </p>
          <p style={{ opacity: slideIndex == 8 ? 1 : 0 }}>
            <strong>ATLAS</strong>
            <br />
            Opal Rarity
            <br />
            Limited Edition
            <br />
            #THE ONE/2001
            <br />
            <br />
            by
            <br />
            SMILESSSVRS
          </p>
        </div>
      </div>
      <div className="vid">
        <a
          ref={boxRef}
          className={"boxContainer" + (muted ? "" : " boxContainerActive")}
          onClick={toggleMuted}
        >
          <div className="box box1"></div>
          <div className="box box2"></div>
          <div className="box box3"></div>
          <div className="box box4"></div>
          <div className="box box5"></div>
        </a>
        <Fade {...properties}>
          <video
            ref={titanRef}
            playsInline
            loop
            muted={slideIndex != 0 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/TITAN_WHITESILVER_EMERALD_005.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={atlasRef}
            playsInline
            loop
            muted={slideIndex != 1 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/ATLAS_BLACKPURPLE_SAPPHIRE_023.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={vegaRef}
            playsInline
            loop
            muted={slideIndex != 2 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/VEGA_WHITECAMOPRINT_TOPAZ_048.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={orionRef}
            playsInline
            loop
            muted={slideIndex != 3 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/ORION_GREENPURPLE_RUBY_002.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={titan2Ref}
            playsInline
            loop
            muted={slideIndex != 4 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/TITAN_SOPHIESTURDEVANT_OPAL.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={atlas2Ref}
            playsInline
            loop
            muted={slideIndex != 5 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/ATLAS_SEANWILLIAMS_OPAL.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={vega2Ref}
            playsInline
            loop
            muted={slideIndex != 6 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/VEGA_THEHEARTPROJECT_OPAL.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={orion2Ref}
            playsInline
            loop
            muted={slideIndex != 7 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/ORION_JAVIERARRES_OPAL_0310.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={theOneRef}
            playsInline
            loop
            muted={slideIndex != 8 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/SMILESS_THE_ONE_OPAL_0310.mp4"
              type="video/mp4"
            />
          </video>
        </Fade>
      </div>
      <div className="copy-right hide-mobile">
        <div className="copy-absolute">
          <h2>
            FULLY
            <br />
            ANIMATED
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Nft;
