import * as React from "react";
import { Helmet } from "react-helmet";
import "../styles/genesis.css";
import Loader from "../components/loader.js";
import Icons from "../components/icons.js";
import Teaser from "../components/secondaryTeaser.js";
import Shoes from "../components/genesis/shoes.js";
import Hex from "../components/genesis/hex.js";
import Presale from "../components/genesis/presale.js";
import Discover from "../components/genesis/discover.js";
import Nft from "../components/genesis/nft.js";
import Collab from "../components/genesis/collab.js";
import CollabStatic from "../components/genesis/collab-static.js";
import Footer from "../components/footer.js";
import Join from "../components/join.js";
import HomeSVG from "../images/2.0/home.svg";

var Scroll = require("react-scroll");
var scroll = Scroll.animateScroll;

const isBrowser = typeof window !== "undefined";

// markup
const GenesisPage = () => {

  const endRef = React.useRef(null);
  const [hasScrolled, setHasScrolled] = React.useState(false);

  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  const [isVideoSent, setIsVideoSent] = React.useState(false);
  const [teaserSrc, setTeaserSrc] = React.useState(null);
  const [orientation, setOrientation] = React.useState("landscape");

  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0);
  const [height, setHeight] = React.useState(
    isBrowser ? window.innerHeight : 0
  );

  const [footerVisible, setFooterVisible] = React.useState(false);

  const [shoesHeight, setShoesHeight] = React.useState(0);
  const [hexHeight, setHexHeight] = React.useState(0);
  const [presaleHeight, setPresaleHeight] = React.useState(0);
  const [discoverHeight, setDiscoverHeight] = React.useState(0);
  const [nftHeight, setNftHeight] = React.useState(0);

  const [joinHeight, setJoinHeight] = React.useState(0);
  const [collabHeight, setCollabHeight] = React.useState(0);

  const [muted, setMuted] = React.useState(true);
  const [active, setActive] = React.useState(true);

  const boxRef = React.useRef(null);

  const updateWidthAndHeight = () => {
    setWidth(isBrowser ? window.innerWidth : 0);
    setHeight(isBrowser ? window.innerHeight : 0);
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  const updateScrollPosition = () => {
    if (!boxRef.current) return;
    if ((isBrowser ? window.scrollY : 800) > height) {
      boxRef.current.style.display = "none";
    } else {
      boxRef.current.style.display = "flex";
    }
  };

  const setScreenOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrientation("portrait");
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrientation("landscape");
    }
  };
  const onLoadEnd = () => {
    setIsVideoLoaded(true);
  };
  React.useEffect(() => {
    setTeaserSrc(
      process.env.NODE_ENV==='development'?"http://localhost:8000/Cult&RAIN_Trailer_211212a_20mb_notext.mp4":"https://cdn.cultandrain.com/Cult&RAIN_Trailer_211212a_20mb_notext.mp4"
    );

    setScreenOrientation();
    updateWidthAndHeight();
    if (isBrowser) window.addEventListener("resize", updateWidthAndHeight);
    if (isBrowser) window.addEventListener("scroll", updateScrollPosition);
    if (isBrowser)
      window.addEventListener("orientationchange", setScreenOrientation);
    return () => {
      if (isBrowser) window.removeEventListener("resize", updateWidthAndHeight);
      if (isBrowser) window.removeEventListener("scroll", updateScrollPosition);
      if (isBrowser)
        window.removeEventListener("orientationchange", setScreenOrientation);
    };
  });
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GENESIS COLLECTION | CULT&amp;RAIN™</title>
        <link rel="canonical" href="https://www.cultandrain.com/genesis" />
        <meta
          name="description"
          content="Luxury fashion born from Web3, merging NFTs with exclusive physical redeemables."
        />
      </Helmet>
      {isBrowser && (
        <div className="genesisPage">
          <div className="wrapper">
            {!isVideoLoaded && <Loader />}
            {isVideoLoaded && (
              <div>
                <a href="/" className="homebtn"><img src={HomeSVG} /></a>
                <Icons
                  footerVisible={footerVisible}
                  mintVisible={false}
                  page="genesis"
                />
                <a
                  ref={boxRef}
                  className={
                    "boxContainer" + (muted ? "" : " boxContainerActive")
                  }
                  onClick={toggleMuted}
                >
                  <div className="box box1"></div>
                  <div className="box box2"></div>
                  <div className="box box3"></div>
                  <div className="box box4"></div>
                  <div className="box box5"></div>
                </a>
              </div>
            )}

            <div
              className="page"
            >
              <Teaser
                height={height}
                muted={muted}
                teaserSrc={teaserSrc}
                landing={true}
                onLoadEnd={onLoadEnd}
              />
              <div ref={endRef} />
              {isVideoLoaded && (
                <div>

                  <Shoes
                    start={0}
                    height={height*.75}
                    total={setShoesHeight}
                    orientation={orientation}
                  />

                  <Hex
                    start={shoesHeight}
                    height={height}
                    total={setHexHeight}
                    orientation={orientation}
                  />
                  <Presale
                    start={
                      shoesHeight +
                      hexHeight
                    }
                    height={height}
                    total={setPresaleHeight}
                  />
                  <Discover
                    start={
                      shoesHeight +
                      hexHeight +
                      presaleHeight
                    }
                    height={height}
                    total={setDiscoverHeight}
                    orientation={orientation}
                  />
                  <Nft
                    start={
                      shoesHeight +
                      hexHeight +
                      presaleHeight +
                      discoverHeight
                    }
                    height={height}
                    total={setNftHeight}
                    orientation={orientation}
                  />
                  {orientation == "portrait" && (
                    <CollabStatic total={setCollabHeight} />
                  )}
                  {orientation == "landscape" && (
                    <Collab
                      start={
                        shoesHeight +
                        hexHeight +
                        presaleHeight +
                        discoverHeight +
                        nftHeight
                      }
                      height={height}
                      total={setCollabHeight}
                    />
                  )}
                  <Join start={
                    shoesHeight+hexHeight+presaleHeight+collabHeight+discoverHeight+nftHeight} height={height} total={setJoinHeight} />
                  <Footer
                    start={
                      shoesHeight +
                      hexHeight +
                      presaleHeight +
                      collabHeight +
                      discoverHeight +
                      nftHeight +
                      joinHeight
                    }
                    height={height}
                    setFooterVisible={setFooterVisible}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <form
        style={{ display: "none" }}
        name="Join"
        method="POST"
        data-netlify="true"
      >
        <input type="email" name="email" />
      </form>
    </main>
  );
};

export default GenesisPage;
