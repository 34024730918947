import * as React from "react";
import Plx from "react-plx";
import HexTop from "../../images/2.0/hex-top.jpg";

const isBrowser = typeof window !== "undefined";

const Hex = (props) => {
  const hexRef = React.useRef(null);

  let hexData = [
    {
      start: props.start,
      end: props.start + props.height,
      easing: [0.42, 0.0, 1.0, 1.0],
      properties: [
        {
          startValue: props.orientation == "portrait" ? 0 : -30,
          endValue: props.orientation == "portrait" ? 0 : 0,
          property: "bottom",
          unit: "vh",
        },
      ],
    },
  ];

  React.useEffect(() => {
    props.total(hexRef.current ? hexRef.current.clientHeight : 0);
    return () => {};
  });

  return (
    <div ref={hexRef} className="hex">
      <img src={HexTop} />
    </div>
  );
};

export default Hex;
